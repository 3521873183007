import type { DeepPartial } from '@noths/polaris-dev-ts-recipes';
import { createSelector } from '@reduxjs/toolkit';

import { selectPageType } from 'src/redux/navigation/selectors';
import {
  selectCurrentPage,
  selectPartnerProductsUrl,
  selectTotalPages,
} from 'src/redux/products/selectors';
import { PageType } from 'src/types/navigation';
import type { UpdatedReduxState } from 'src/types/page';
import type { ReduxApplicationState } from './combinedReducer';

export const selectCanonicalTag = (state: ReduxApplicationState) => {
  switch (selectPageType(state)) {
    case PageType.Category:
      return state.content!.canonicalTag;
    case PageType.Partner:
      return `${selectPartnerProductsUrl(state)}${
        selectCurrentPage(state) > 1 ? `?page=${selectCurrentPage(state)}` : ''
      }`;
    default:
      return '';
  }
};

export const selectPaginationTagValues = createSelector(
  [selectCanonicalTag, selectCurrentPage, selectTotalPages],
  (canonicalTag, currentPage, totalPages) => {
    const currentUrl = canonicalTag.split('?')[0];
    const prevPageParam = currentPage === 2 ? '' : `?page=${currentPage - 1}`;
    const nextPageParam = `?page=${currentPage + 1}`;

    const prev = currentPage > 1 ? `${currentUrl}${prevPageParam}` : null;
    const next = currentPage < totalPages ? `${currentUrl}${nextPageParam}` : null;

    return [prev, next];
  },
);

interface PagePropsForClientHydration {
  updatedReduxState: DeepPartial<UpdatedReduxState>;
}

export const selectPagePropsForClientHydration = ({
  content,
  filter,
  filterMenu,
  navigation,
  products,
  productsListQuery,
  user,
  userConfiguration,
}: ReduxApplicationState): PagePropsForClientHydration => {
  return {
    updatedReduxState: {
      content,
      products,
      productsListQuery,
      user,
      userConfiguration: userConfiguration as DeepPartial<typeof userConfiguration>,
      filter: {
        collections: filter.collections,
      },
      filterMenu: {
        sortingOptions: filterMenu.sortingOptions,
        selectedSortOption: filterMenu.selectedSortOption,
      },
      navigation,
    },
  };
};
