import { connect } from 'react-redux';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import {
  selectPartnerHomepageRelativeUrl,
  selectPartnerHomepageUrl,
  selectPartnerLogoUrl,
  selectPartnerName,
} from 'src/redux/products/selectors';
import { selectPaginationTagValues } from 'src/redux/selectors';
import { PartnerListing } from './PartnerListing';

const mapStateToProps = (state: ReduxApplicationState) => {
  return {
    partnerName: selectPartnerName(state),
    partnerLogoUrl: selectPartnerLogoUrl(state),
    partnerHomepageUrl: selectPartnerHomepageUrl(state),
    partnerHomepageRelativeUrl: selectPartnerHomepageRelativeUrl(state),
    paginationTagValues: selectPaginationTagValues(state),
    pageLiveRegionText: state.accessibility.statusMessage,
  };
};

export const PartnerListingContainer = connect(mapStateToProps)(PartnerListing);
