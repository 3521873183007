import React from 'react';
import { batch } from 'react-redux';
import type { CookieObj } from '@noths/polaris-client-gdpr-compliance';
import { acceptsFunctionalCookies } from '@noths/polaris-client-gdpr-compliance';
import { parseLocaleSetCookieHeaders } from '@noths/polaris-client-localisation';
import {
  actions as userConfigurationActions,
  getOrSetRichRelevanceSessionId,
} from '@noths/polaris-client-user-configuration';
import { type CurrencyCode, StatusCode } from '@noths/polaris-dev-ts-types';
import type { GetServerSideProps } from 'next';
import getConfig from 'next/config';
import Head from 'next/head';
import { parse } from 'url';

import type { PartnerListingProps } from 'src/components/layouts/PartnerListing/PartnerListing';
import { PartnerListingContainer } from 'src/components/layouts/PartnerListing/PartnerListingContainer';
import { ListingsApiErrorGuardContainer } from 'src/components/molecules/ListingsApiErrorGuard/ListingsApiErrorGuardContainer';
import { getFeatureFlagsWithMatchers } from 'src/featureFlags/getFeatureFlagsWithMatchers';
import { actions as navigationActions } from 'src/redux/navigation/slice';
import {
  selectPartnerHomepageRelativeUrl,
  selectPartnerHomepageUrl,
  selectPartnerName,
} from 'src/redux/products/selectors';
import { requestProducts } from 'src/redux/products/thunks/requestProducts';
import { PARTNER_NOT_FOUND_ERROR } from 'src/redux/products/thunks/thunkErrors';
import { selectPagePropsForClientHydration, selectPaginationTagValues } from 'src/redux/selectors';
import { getStore } from 'src/redux/store';
import { fetchGlobalContent } from 'src/services/contentstack/fetchGlobalContent';
import * as redirection from 'src/services/redirection';
import { PageType } from 'src/types/navigation';
import type { NextConfig } from 'src/types/nextConfig';
import type { PageProps } from 'src/types/page';
import { getPartnerBreadcrumbs } from 'src/utils/getPartnerBreadcrumbs';
import { getProductsJSONLDSchema } from 'src/utils/getProductsJSONLDSchema';

const { serverRuntimeConfig } = getConfig() as NextConfig;

export const getServerSideProps: GetServerSideProps = async ({ query, req, res }) => {
  const { partnerId, ...queryParams } = query;

  if (partnerId) {
    const pathname = parse(req.url!).pathname!;
    const { cognitoId, currency, delivery_zone, rr_session } = req.cookies;
    const { currency: initialCurrency, deliveryZone: initialDeliveryZone } =
      parseLocaleSetCookieHeaders(res.getHeaders());
    const currencyCode = (initialCurrency ?? currency) as CurrencyCode;
    const { dispatch, getState } = getStore(
      {
        router: { pathname, query: queryParams },
      },
      {
        navigation: {
          pageType: PageType.Partner,
        },
        productsListQuery: {
          partnerShortcode: partnerId as string,
        },
        user: {
          cognitoID: cognitoId || null,
        },
        userConfiguration: {
          currencyCode,
        },
      },
    );
    const functionalCookiesConsented = acceptsFunctionalCookies(req.cookies as CookieObj);
    const richRelevanceSessionId = getOrSetRichRelevanceSessionId(res, rr_session);
    const featureFlags = (req && getFeatureFlagsWithMatchers(req)) || {};

    batch(() => {
      dispatch(navigationActions.setPathname({ url: req.url ?? '', host: req.headers.host ?? '' }));
      dispatch(userConfigurationActions.setDeliveryZone(initialDeliveryZone ?? delivery_zone));
      dispatch(userConfigurationActions.setFeatureFlags(featureFlags));
      dispatch(userConfigurationActions.setRichRelevanceSessionId(richRelevanceSessionId));
    });

    const urlPath = req.url;
    const [, [navData, topBannerData]] = await Promise.all([
      dispatch(requestProducts({ query: queryParams })),
      fetchGlobalContent({ featureFlags, urlPath }),
    ]);

    const state = getState();
    const { products } = state;

    const redirect = redirection.productGuard(products, query, pathname);

    if (redirect) {
      return redirect;
    }

    if (products.error) {
      res.statusCode =
        products.error.name === PARTNER_NOT_FOUND_ERROR.name
          ? StatusCode.NotFound
          : StatusCode.InternalServerError;

      if (res.statusCode === StatusCode.InternalServerError) {
        res.setHeader('Retry-After', serverRuntimeConfig.retryAfterDuration);
      }

      const props: Partial<PartnerListingProps> = {
        paginationTagValues: [],
        navData,
        topBannerData,
        functionalCookiesConsented,
        updatedReduxState: {
          // Cherry pick state to be updated on the client
          products,
          navigation: { pageType: PageType.Partner },
        },
      };

      return {
        props,
      };
    }

    const breadcrumbLinks = getPartnerBreadcrumbs(
      selectPartnerName(state),
      selectPartnerHomepageUrl(state),
      selectPartnerHomepageRelativeUrl(state),
    );

    const props: Partial<PartnerListingProps> = {
      breadcrumbLinks,
      featureFlags,
      navData,
      topBannerData,
      functionalCookiesConsented,
      paginationTagValues: selectPaginationTagValues(state),
      partnerName: selectPartnerName(state),
      partnerHomepageUrl: selectPartnerHomepageUrl(state),
      ...selectPagePropsForClientHydration(state),
    };

    return {
      props,
    };
  } else {
    const props = {
      updatedReduxState: {
        products: {
          error: {
            message: 'An unknown error occurred',
          },
        },
      },
    } as PageProps;

    return {
      props,
    };
  }
};

const PartnerProductsPage = (props: PartnerListingProps) => {
  const [prevMetaTag, nextMetaTag] = props.paginationTagValues || [];
  const JSONLDSchema = getProductsJSONLDSchema(props.breadcrumbLinks);

  return (
    <>
      <Head>
        {/* In browsers title Elements can only have a single Text Node as children */}
        <title>{`${props.partnerName} | Products`}</title>
        <link href={props.canonicalTag} rel="canonical" />
        {prevMetaTag && <link href={prevMetaTag} rel="prev" />}
        {nextMetaTag && <link href={nextMetaTag} rel="next" />}
        <meta content={props.metaTagDescription} name="description" />
        <meta content={props.partnerName} property="og:title" />
        <meta content="profile" property="og:type" />
        <meta content={props.partnerLogoUrl} property="og:image" />
        <meta content={props.partnerHomepageUrl} property="og:url" />
        <script
          dangerouslySetInnerHTML={{ __html: JSON.stringify(JSONLDSchema) }}
          type="application/ld+json"
        />
      </Head>
      <ListingsApiErrorGuardContainer>
        <PartnerListingContainer {...props} />
      </ListingsApiErrorGuardContainer>
    </>
  );
};

export default PartnerProductsPage;
