import React, { useEffect } from 'react';
import * as recommendations from '@noths/polaris-client-recommendations';
import { grid, maxWidthContainer } from '@noths/polaris-client-ribbons-base';
import { visuallyHidden } from '@noths/polaris-client-styles';
import { getUserAttributes } from '@noths/polaris-client-user-configuration';
import { addRUMTiming } from '@noths/polaris-client-utils';
import type { NonNullableLink } from '@noths/polaris-dev-ts-types';
import getConfig from 'next/config';

import { BackToTopButton } from 'src/components/molecules/BackToTopButton/BackToTopButton';
import { BreadcrumbsContainer } from 'src/components/molecules/Breadcrumbs/BreadcrumbsContainer';
import { MembershipBannerSection } from 'src/components/molecules/MembershipBannerSection/MembershipBannerSection';
import { PartnerHeader } from 'src/components/molecules/PartnerHeader/PartnerHeader';
import { FilterContainer } from 'src/components/organisms/Filter/containers/FilterContainer';
import { QuickFiltersContainer } from 'src/components/organisms/Filter/containers/QuickFiltersContainer';
import { SlimFilterMenuContainer } from 'src/components/organisms/FilterMenu/containers/SlimFilterMenuContainer';
import { ProductPagesContainer } from 'src/components/organisms/ProductPages/containers/ProductPagesContainer';
import { RecommendedProductsShelfContainer } from 'src/components/organisms/RecommendedProductsShelf/containers/RecommendedProductsShelfContainer';
import * as elementIds from 'src/constants/elementIds';
import { Placement } from 'src/constants/recommendations';
import { useFilterNavType } from 'src/hooks/useFilterNavType/useFilterNavType';
import { actions as navigationActions } from 'src/redux/navigation/slice';
import { requestRecommendedProducts } from 'src/redux/richRelevance/thunks';
import { useStore } from 'src/redux/store';
import * as styles from 'src/styles/pages.styles';
import type { NextConfig } from 'src/types/nextConfig';
import type { PageProps } from 'src/types/page';
import { transformBreadcrumbsLinks } from 'src/utils/transformBreadcrumbsLinks';

const { publicRuntimeConfig } = getConfig() as NextConfig;

export type PartnerListingProps = PageProps & {
  breadcrumbLinks: NonNullableLink[];
  canonicalTag: string;
  metaTagDescription: string;
  pageLiveRegionText: string;
  paginationTagValues: (string | null)[];
  partnerHomepageUrl: string;
  partnerLogoUrl: string;
  partnerName: string;
};

export const PartnerListing = ({
  breadcrumbLinks,
  functionalCookiesConsented,
  pageLiveRegionText,
  partnerLogoUrl,
  partnerName,
  updatedReduxState,
}: PartnerListingProps) => {
  const { dispatch } = useStore();

  useFilterNavType((navType) => dispatch(navigationActions.setInitialPageLoad(navType)));

  useEffect(() => {
    getUserAttributes()
      .then((attributes) =>
        recommendations.initRecommendations({
          apiKey: publicRuntimeConfig.RICH_RELEVANCE_API_KEY,
          env: process.env.NODE_ENV,
          hasDisabledCookies: !functionalCookiesConsented,
          sessionId: updatedReduxState?.userConfiguration?.richRelevanceSessionId,
          userId: attributes?.sub,
        }),
      )
      .then(async () => {
        recommendations.setPartnerProductsPage(partnerName);

        const placementData = await recommendations.getRecommendations([
          Placement.PartnerListingsRecentlyViewed,
        ]);

        const recommendedProductsRequests = placementData.map((placement) =>
          dispatch(requestRecommendedProducts(placement)),
        );

        await Promise.allSettled(recommendedProductsRequests);
        addRUMTiming('recommendationsLoaded');
      })
      .catch((e) => {
        console.warn(
          `Could not load recommendations on partner product listings page: ${e.message}`,
        );
      });
  });

  return (
    <>
      <div css={visuallyHidden} role="status">
        {pageLiveRegionText}
      </div>
      <div css={styles.wrapper}>
        <div css={[grid, maxWidthContainer]}>
          <div css={styles.headerContentWrapper}>
            <BreadcrumbsContainer breadcrumbsLinks={transformBreadcrumbsLinks(breadcrumbLinks)} />
          </div>
        </div>
        <div css={[grid, maxWidthContainer]}>
          <div css={styles.headerContentWrapper}>
            <PartnerHeader partnerLogoUrl={partnerLogoUrl} partnerName={partnerName} />
          </div>
        </div>
        <div id={elementIds.PRODUCT_CONTAINER_WRAPPER}>
          <SlimFilterMenuContainer />
          <div css={styles.quickFiltersWrapper}>
            <QuickFiltersContainer />
          </div>
          <div css={[grid, maxWidthContainer, styles.productListWrapper]}>
            <div css={styles.productListSidebar}>
              <FilterContainer />
            </div>
            <div css={styles.productPagesWrapper}>
              <ProductPagesContainer />
              <RecommendedProductsShelfContainer
                placementType={Placement.CategoryListingsRecentlyViewed}
              />
              <MembershipBannerSection
                buttonHref="/membership"
                buttonText="Tell me more"
                copy="Join our <strong>NOTHS & More</strong> membership and get 10% off every single order – including sale."
                heading="Get 10% off any of these amazing finds"
                trackingLocation="partner"
              />
              <BackToTopButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
