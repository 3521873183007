import type { Link } from '@noths/polaris-dev-ts-types';

type BreadcrumbListContext = 'https://schema.org';
type BreadcrumbListType = 'BreadcrumbList';
type ListItemType = 'ListItem';

export interface ListItem {
  '@type': ListItemType;
  item?: string;
  name?: string;
  position: number;
}

export interface BreadcrumbJSONLDSchema {
  '@context': BreadcrumbListContext;
  '@type': BreadcrumbListType;
  itemListElement: ListItem[];
}

export const getBreadcrumbsListItems = (breadcrumbsLinks: Link[]): ListItem[] => {
  return breadcrumbsLinks.map(({ href, title }, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: title || '',
    item: href || undefined,
  }));
};

export const getProductsJSONLDSchema = (breadcrumbsLinks: Link[] = []): BreadcrumbJSONLDSchema => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: getBreadcrumbsListItems(breadcrumbsLinks),
  };
};
